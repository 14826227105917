<template>
    <div>        
        <reqs :reqs="requisitos" :key="requisitos"></reqs>
    </div>
</template>
<script>
    import reqs from "./ReqTable.vue";
    /*import {Surveys} from "../../../services/Arena.js";
    const sv = new Surveys;*/

    export default {
            name: "SurveyChapStruct",
      data: () => ({
            title: "Estrutura Survey",
          requisitos: [],
        // Data for data[indextr]
        RankingList: [
          ],
          SurveyStruct: {}
        }),
        components: {
            reqs,
        },
        props: {
            chap: Number            
        },
        created() {    
           
            this.SurveyStruct = this.$store.state.SurveyStruct;

            //procurar o chap
            var i;
            var j;
            var achou = false;

            for (i = 0; i < this.SurveyStruct.chapters.length; i++)
            {                
                if (this.SurveyStruct.chapters[i].code == this.chap) {
                    this.requisitos = this.SurveyStruct.chapters[i].requirements
                    achou = true;
                    break;
                }
                else
                {             
                    if (this.SurveyStruct.chapters[i].subChapters)
                    {                 
                        for (j = 0; j < this.SurveyStruct.chapters[i].subChapters.length; j++)
                        {
                     
                            var scp = this.SurveyStruct.chapters[i].subChapters[j];
                            if (scp.code == this.chap) {                                
                                this.requisitos = scp.requirements
                                achou = true;
                                break;
                            }
                        }
                    }
                }   
                if (achou)
                    break;
            }            
        },
        methods:
        {
            temSubChapters(idx) {
                if (this.SurveyStruct.data.chapters[idx].subChapters != null)
                    return true
                else
                    return false;
            },
            temReqs(idx) {
                if (this.SurveyStruct.data.chapters[idx].requirements != null)
                    return true
                else
                    return false;
            },
            
        }
};
</script>
