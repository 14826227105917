<template>
    <div>
        <div v-bar class="vs-scrollable">
            <div>          
                <vs-table :data="reqs">
                    <!--class="text-nowrap"-->
                    <template slot="thead">
                        <vs-th>Det.</vs-th>
                        <vs-th>Code</vs-th>
                        <vs-th>description</vs-th>
                        <vs-th>Peso</vs-th>
                        <vs-th>Nota</vs-th>
                        <vs-th>Calculada</vs-th>
                        <vs-th>Ponderada</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr class="border-top" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td><vs-button @click="selectTr(indextr)" color="primary" type="flat" icon="search"></vs-button></vs-td>
                            <vs-td>{{ tr.code }}</vs-td>
                            <vs-td>{{ tr.description }}</vs-td>
                            <vs-td>{{ tr.factor }}</vs-td>
                            <vs-td>{{ tr.score }}</vs-td>
                            <vs-td>{{ tr.adjustedScore }}</vs-td>
                            <vs-td>{{ tr.NewScore }}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
        <vs-popup fullscreen title="Detalhe requisito" :active.sync="popupActivo4">
            <p>
                <vs-table :data="SelectedTrs">
                    <!--class="text-nowrap"-->
                    <template slot="thead">
                        <vs-th>
                            <vs-button @click="selectPrevTr()" color="primary" type="flat" icon="expand_less" v-show="ShowPrev"></vs-button>
                            <vs-button @click="selectNextTr()" color="primary" type="flat" icon="expand_more" v-show="ShowNext"></vs-button>
                        </vs-th>
                        <vs-th>description</vs-th>
                        <vs-th>Peso</vs-th>
                        <vs-th>Nota</vs-th>
                        <vs-th>Calculada</vs-th>
                        <vs-th>Ponderada</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr class="border-top" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>{{ tr.code }}</vs-td>
                            <vs-td>{{ tr.description }}</vs-td>
                            <vs-td>{{ tr.factor }}</vs-td>
                            <vs-td>{{ tr.score }}</vs-td>
                            <vs-td>{{ tr.adjustedScore }}</vs-td>
                            <vs-td>{{ tr.NewScore }}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>                
                <vs-table :data="SelectedTr.questions">
                    <template slot="thead">
                        <vs-th>id</vs-th>
                        <vs-th>Pergunta</vs-th>
                        <vs-th>Resposta</vs-th>
                        <vs-th>Nível</vs-th>
                        <vs-th>Calculada</vs-th>
                        <!--
            <vs-th>Peso - {{somarPeso()}}</vs-th>
            -->
                    <vs-th>
                        Peso - {{somaPesos}}</vs-th>
                        <vs-th>Pond.</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr class="flex" :data="SelectedTr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>{{ tr.id
                        }}</vs-td>
                        <vs-td>{{ tr.description }}</vs-td>
                        <vs-td>{{ resposta(tr) }}</vs-td>
                        <vs-td>{{ tr.answer.fullFillment }}%</vs-td>
                        <vs-td>{{ notaResposta(tr) }}</vs-td>
                        <vs-td>{{ tr.factor }}</vs-td>
                        <vs-td>{{ tr.answer.adjustedScore }}</vs-td>
                        </vs-tr>
</template>
                </vs-table>
            </p>
        </vs-popup>
   
    </div>
</template>
<script>

    export default {
        name: "ReqTable",
        data: () => ({
            title: "ReqTable",
            popupActivo4: false,
            SelectedTrs: [],
            SelectedTr: {},
            SelectedTrId: 0,
            ShowNext: true,
            ShowPrev: true,
            somaPesos: 0

        }),
        props: {
            reqs: []
        },
        created() {
            /* this.$vs.loading();
             st.Get(this.pais).then((resp) => {
                 this.StadiumsList = resp;
                 this.$store.commit("SET_stadiumList", resp);
                 this.$vs.loading.close();
             }, (err) => alert(err));
             */
        },
        computed: {
        },
        methods: {

            somarPeso() {

                var tot = 0;
               
                for (var i = 0; i < this.SelectedTr.questions.length; i++)
                {                  
                    tot += this.SelectedTr.questions[i].factor;
                }


                this.somaPesos = tot;
            },

            TitlePopUp() {
                return this.SelectedTr.description + " Nota: " + this.SelectedTr.score + " Calculada: " + this.SelectedTr.adjustedScore;                
            },
            selectNextTr() {                
                if (this.SelectedTrId < this.reqs.length)
                {
                    var indextr = this.SelectedTrId + 1;
                    this.SelectedTr = this.reqs[indextr];
                    this.SelectedTrs = [];
                    this.SelectedTrs.push(this.SelectedTr);
                    this.SelectedTrId = indextr;
                    this.somarPeso();
                    this.setArrows();
                }
                        
            },
            selectPrevTr() {
                if (this.SelectedTrId > 0)
                {
                    var indextr = this.SelectedTrId - 1;
                    this.SelectedTr = this.reqs[indextr];
                    this.SelectedTrs = [];
                    this.SelectedTrs.push(this.SelectedTr);
                    this.SelectedTrId = indextr;
                    this.somarPeso();
                    this.setArrows();
                }

            },
            selectTr(indextr) {
                this.SelectedTr = this.reqs[indextr];
                this.SelectedTrs = [];
                this.SelectedTrs.push(this.SelectedTr);
                this.SelectedTrId = indextr;
                this.popupActivo4 = true;
                this.somarPeso();
                this.setArrows();

            },
            setArrows() {

                if (this.SelectedTrId == 0)
                    this.ShowPrev = false;
                else
                    this.ShowPrev = true;

                if (this.SelectedTrId == this.reqs.length - 1)
                    this.ShowNext = false;
                else
                    this.ShowNext = true;
            },
            resposta(tr) {
                var s = "";
                if (tr.answer) {
                    if (tr.answer.toShow)
                        s = tr.answer.toShow;
                    else
                        s = tr.answer.text;
                    /*
                    if (tr.type == 2)
                    {
                        if (tr.text == "1")
                            s = "Sim";
                        else
                            s = "Não";
                    }
                    else
                        s = tr.answer.text;
                        */
                }
                return s;
            },
            notaResposta(tr) {
                var s = "";
                if (tr.answer) {

                    s = tr.answer.score;
                }
                return s;
            }
        }
    };
</script>
