<template>
  <vs-row vs-justify="center">       
    <vs-col vs-lg="12" vs-xs="12" vs-sm="12">
      <vs-card>                 
          <div class="d-md-flex">
              <div class="flex">
                  <h4>{{title}}</h4>
              </div>              
          </div>
        <SurveytructTable2 :chap="chap"  :key="SurveyStruct"></SurveytructTable2>
      </vs-card>
    </vs-col>  
  </vs-row>
</template>
<script>
    // //////////////////////////////////////////
    // Import Components Here
    // //////////////////////////////////////////

    import SurveytructTable2 from "../survey-table/SurveyChapStruct2.vue";

    //import { Surveys } from "../../../services/Arena.js";
    //const sv = new Surveys;

// //////////////////////////////////////////
export default {
  name: "Surveys",
  data: () => ({
    title: "Surveys",
    // Month Table
    filter1: 0,
    filteroptions1: [
      { text: "Todos os capitulos", value: 0 },
      { text: "Capitulo 1", value: 1 },
      { text: "Capitulo 2", value: 2 },      
      ],
      chap: 0,
      //subchap: 0,
      SurveyStruct: {},
      SurveyScoreTable:[]
  }),
  components: {
    SurveytructTable2,    
        },
        created()
        {
            

            this.setSideBar(true);

            this.chap = this.$route.params.chap;
            //this.subchap = this.$route.params.subchap;

            
            this.SurveyStruct = this.$store.state.SurveyStruct;
            /*
            var cp = this.SurveyStruct[this.chap];
            var scp = {};

            this.title = cp.name;

            if (this.subchap) {
                scp = cp.subChapters[this.subchap];
                this.title = this.title + " / " + scp.name;
            }
            */

            //pegar as respostas
            /*
            this.$vs.loading();
            //GetStruct GetScore

            var id = this.$store.state.SelectedSurvey;
            alert(id);

            sv.GetScore(id, cp.id).then((resp) => {            
                this.SurveyStruct = resp.data.survey.chapters;
                this.SurveyScoreTable = resp.data.survey.ScoreChapTable;
                this.$store.commit("SET_SurveyStruct", this.SurveyStruct);                
                this.$vs.loading.close();
            }, (err) => alert(err));
           */

        },
         methods: {
             setSideBar(val) {
            this.$store.commit("TOGGLE_REDUCE_SIDEBAR", val);
          }
        }
};
</script>